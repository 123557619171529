import { isAuthenticated } from "@/utils/helpers/auth";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setCurrentProduct } from "@/store/feature/productSlice";
import { addToWishList, removeWishList } from "@/api/wishlist";
import en from "@/localization/en.json";
import classNames from "classnames";
import StockOutBadge from "@/components/data-display/stock-out-badge";
import AddToCartModal from "@/components/modal/AddToCartModal";
import Rating from "@/components/data-display/Rating";
import { ShoppingCartIcon } from "@/components/data-display/Icon";

function Card({ product: propProductData, showDiscount = true }) {
  const [product, setProductData] = useState(propProductData);

  const addToCardRef = useRef();
  const dispatch = useDispatch();
  const router = useRouter();
  const { name, price } = product;
  const totalRatingCount = product?.reviews?.total_rating_count;

  const [isWishlist, setIsWishlist] = useState(
    product?.default_variation?.in_wishList
  );

  const handleAddToCart = () => {
    addToCardRef.current.click();
    dispatch(setCurrentProduct(product));
  };

  useEffect(() => {
    setProductData(propProductData);
    setIsWishlist(propProductData?.default_variation.in_wishList);
  }, [propProductData, setIsWishlist]);

  const handleWishlist = async () => {
    if (!(await isAuthenticated(router))) return;

    const productData = {
      product: product?.slug,
    };

    let firstVariation;

    firstVariation = product?.default_variation;

    productData.variation = firstVariation?.variation;

    if (isWishlist) {
      let res = await removeWishList(productData);
      if (res && res?.data) {
        setIsWishlist(false);
      }
    } else if (!isWishlist) {
      let res = await addToWishList(productData);
      if (res && res?.data) {
        setIsWishlist(true);
      }
    }
  };

  const calculatePrice = (product) => {
    let first_variation = product.default_variation;

    return [
      first_variation?.price,
      first_variation?.offer_price,
      first_variation?.offer_percent,
    ];
  };

  return (
    <>
      <div className="group w-full rounded-xl relative">
        <div className="border-2 border-transparent group-hover:border-[#EDF3F7] rounded-xl transition delay-150 duration-300 ease-in-out flex flex-col justify-between">
          <Link href={`/product/${product.slug}`}>
            <div
              className={`w-full flex justify-center items-center transition delay-150 duration-300 ease-in-out group-hover:bg-[#F3F6F8] cursor-pointer aspect-square rounded-t-xl`}
            >
              <div className="relative w-full h-full">
                {product?.main_picture && (
                  <Image
                    src={product?.main_picture}
                    alt={name}
                    className={classNames(
                      {
                        "opacity-30": product.total_stock === 0,
                      },
                      "rounded-t-xl object-contain"
                    )}
                    fill
                    sizes="(max-width: 768px) 50vw, 33vw"
                  />
                )}
              </div>
              {product.total_stock === 0 && <StockOutBadge />}
            </div>
          </Link>

          <div
            className={classNames(
              {
                "opacity-70": product.total_stock === 0,
              },
              "pb-[1.125rem] px-[0.938rem]"
            )}
          >
            <div className="space-y-[1rem]">
              <Link href={`/product/${product.slug}`}>
                <h3 className="text-[1.063rem] text-[#0A101A] mt-[1.125rem] cursor-pointer h-[3.125rem] line-clamp-2">
                  {name}
                </h3>
              </Link>

              <div className="h-6">
                {totalRatingCount > 0 && (
                  <div className="flex items-center space-x-[0.625rem] ">
                    <Rating
                      ratingNumber={product?.reviews?.average_product_rating}
                    />
                    <p>{`(${totalRatingCount})`}</p>
                  </div>
                )}
              </div>

              {!showDiscount ? (
                <p className="text-[1.375rem] font-semibold mt-[7px]">
                  ৳{price}
                </p>
              ) : (
                <p className=" flex items-center flex-wrap">
                  <span className="text-[1.375rem] font-semibold mr-[0.625rem]">
                    ৳
                    {calculatePrice(product)[1]
                      ? calculatePrice(product)[1]
                      : calculatePrice(product)[0]}
                  </span>
                  {calculatePrice(product)[1] > 0 && (
                    <span className="lg:flex items-center space-x-[0.625rem] flex-wrap  hidden ">
                      <span className="text-[#7B838F] text-[1.125rem] line-through ">
                        ৳{calculatePrice(product)[0]}
                      </span>
                      <span className="bg-[#FF912C] text-[0.75rem] rounded-[0.25rem] px-[0.563rem] py-[0.313rem] text-white font-light ">
                        {calculatePrice(product)[2]}
                        {en["product.discount_percentage"]}
                      </span>
                    </span>
                  )}
                </p>
              )}
            </div>

            <div
              className="hidden sm:flex items-center justify-center border border-primary text-primary group-hover:bg-primary h-[3rem] mt-[1.125rem] space-x-[0.25rem] group-hover:text-white w-full rounded-[0.625rem]  transition delay-150 duration-300 ease-in-out  cursor-pointer"
              onClick={handleAddToCart}
            >
              <ShoppingCartIcon className="text-primary group-hover:text-white text-lg transition delay-150 duration-300 ease-in-out" />
              <p className="rounded-[0.625rem]">{en["myCart.add_to_cart"]}</p>
            </div>

            <div
              className="flex items-center justify-center border border-[#E91F63] h-[3rem] mt-[1.125rem] space-x-[0.25rem] text-[#E91F63] w-full rounded-[0.625rem]  transition delay-150 duration-300 ease-in-out sm:hidden"
              onClick={handleAddToCart}
            >
              <Image
                src="/assets/icons/shopping-cart-mobile.svg"
                width="17"
                height="11"
                alt="cart-icon"
              />
              <p className="rounded-[0.625rem]">{en["myCart.add_to_cart"]}</p>
            </div>
          </div>
        </div>

        <div
          className="absolute top-[0.75rem] right-[0.625rem] sm:top-[0.75rem] sm:right-[0.688rem] cursor-pointer"
          onClick={handleWishlist}
        >
          {isWishlist && (
            <Image
              src="/assets/icons/wishlist-active.svg"
              width={48}
              height={48}
              alt="active-wishlist-icon"
            />
          )}
          {Boolean(!isWishlist) && (
            <div className="w-[48px] h-[48px] relative md:hidden group-hover:block">
              <Image
                src="/assets/icons/wishlist-inactive.svg"
                alt="inactive-wishlist-icon"
                fill
                sizes="640px"
                className="object-contain"
              />
            </div>
          )}
        </div>

        {calculatePrice(product)[1] > 0 && (
          <div className="absolute top-5 left-1 sm:top-5 sm:right-3 cursor-pointer">
            <span className="bg-[#FF912C] text-[0.75rem] rounded-[0.313rem] px-[0.563rem] py-[0.313rem] text-white font-light lg:hidden">
              {calculatePrice(product)[2]}
              {en["product.discount_percentage"]}
            </span>
          </div>
        )}

        <label
          htmlFor="add-to-cart-modal"
          className="hidden"
          ref={addToCardRef}
        >
          {en["myCart.add_to_cart"]}
        </label>
      </div>
      <AddToCartModal />
    </>
  );
}

export default Card;
